
import { defineComponent } from "vue";
import io from "socket.io-client";
import axios from "axios";

export default defineComponent({
  name: "UserManager",
  data() {
    return {
      newUser: "",
    };
  },
  computed: {
    getUsers(): Array<unknown> | null {
      return this.$store.state.userList;
    },
  },
  props: ["socket"],
  methods: {
    addNewUser() {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_API_PORT}/api/user/create/`,
          { userName: this.newUser },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.status === 201) {
            console.log("here");
            this.socket.emit(
              "TRIGGER_UPDATE_USERS",
              this.$store.state.pairMatrix
            );
          }
        });
    },
    removeUser(id: number) {
      axios
        .put(
          `${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_API_PORT}/api/user/remove/`,
          { userId: id }
        )
        .then((res) => {
          console.log("here");
          if (res.status === 204) {
            this.socket.emit(
              "TRIGGER_UPDATE_USERS",
              this.$store.state.pairMatrix
            );
          }
        });
    },
  },
});
