
import { defineComponent } from "vue";
import { PairSet } from "../../../types/PairSet";
import { MutationTypes } from "../../../store/MutationTypes";
import axios from "axios";
import io from "socket.io-client";
import StyledDatePicker from "../../StyledDatePicker/StyledDatePicker.vue";

export default defineComponent({
  name: "MatrixCell",
  props: ["user1Id", "user2Id", "isInEditMode", "socket"],
  components: { StyledDatePicker },
  computed: {
    getPairSetDate(): Date | undefined {
      const pairList: PairSet[] = this.$store.state.pairSetList;
      const foundPairSet = pairList.filter((set) => {
        return (
          set.userIdList.includes(this.user1Id) &&
          set.userIdList.includes(this.user2Id)
        );
      });
      return foundPairSet[0]?.date;
    },
    isPairSelected(): boolean {
      const { selectedPairs } = this.$store.state;
      const foundPairMatch = selectedPairs.filter((pair: string[]) => {
        return pair.includes(this.user1Id) && pair.includes(this.user2Id);
      });
      return foundPairMatch.length > 0 ? true : false;
    },
    getPairSetList(): PairSet[] {
      return this.$store.state.pairSetList;
    },
    getOpacityPercentage(): number {
      const dateList = this.getPairSetList.map((pairSet) => {
        return new Date(pairSet.date).getTime();
      });
      const maxDate = Math.max(...dateList);
      const minDate = Math.min(...dateList);
      const minMaxDateDiff = maxDate - minDate;

      if (!this.getPairSetDate) {
        return 0;
      }
      const thisCellDate = new Date(this.getPairSetDate).getTime();
      const dateDiffFromMin = thisCellDate - minDate;
      const dateDiffPercentage = 1 - dateDiffFromMin / minMaxDateDiff;
      return dateDiffPercentage;
    },
  },
  mounted() {
    this.getOpacityPercentage;
  },
  methods: {
    toggleSelected() {
      const { selectedPairs } = this.$store.state;
      let newSelectedPairList = [];
      if (this.isPairSelected) {
        newSelectedPairList = selectedPairs.filter((pair: string[]) => {
          return !(pair.includes(this.user1Id) && pair.includes(this.user2Id));
        });
      } else {
        newSelectedPairList = [...selectedPairs, [this.user1Id, this.user2Id]];
      }
      this.$store.commit(
        MutationTypes.SET_SELECTED_PAIR_LIST,
        newSelectedPairList
      );
    },
    getUTCDate(date: string) {
      let originalDate = new Date(date);
      let newDate = new Date();
      newDate.setDate(originalDate.getUTCDate());
      return newDate;
    },
    matrixCellEdited(changedDate: Date) {
      const editedPairSet = {
        date: changedDate,
        pairList: [this.user1Id, this.user2Id],
      };
      axios
        .put(
          `${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_API_PORT}/api/pairset/editSinglePairSet`,
          { editedPairSet },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.status === 200) {
            this.$emit("turnEditModeOff");
            this.socket.emit(
              "TRIGGER_UPDATE_PAIR_SETS",
              this.$store.state.pairMatrix
            );
          }
        });
    },
  },
});
