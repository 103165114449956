
import { defineComponent } from "vue";
import NewStairOptions from "./NewStairOptions/NewStairOptions.vue";

export default defineComponent({
  name: "NewStair",
  components: {
    NewStairOptions,
  },
});
