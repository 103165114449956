
import { defineComponent } from "vue";
import io from "socket.io-client";
import { MutationTypes } from "../../store/MutationTypes";
import UserManager from "../UserManager/UserManager.vue";
import MatrixManager from "../MatrixManager/MatrixManager.vue";

export default defineComponent({
  components: { UserManager, MatrixManager },
  name: "MatrixHolder",
  data() {
    return {
      socket: io(
        `${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_API_PORT}`
      ),
      selectedTab: null,
    };
  },
  props: ["matrixName"],
  methods: {
    joinSocket() {
      console.log("joining ", this.getName());
      this.socket.emit("join", { matrixName: this.getName() });
    },
    getName() {
      const firstLetterCapitalized =
        this.matrixName.charAt(0).toUpperCase() +
        this.matrixName.slice(1).toLowerCase();
      return firstLetterCapitalized;
    },
    isUsersTabSelected() {
      if (this.selectedTab === "Matrix") {
        return false;
      }
      return (
        this.selectedTab === "Users" ||
        ((this.selectedTab === null && this.getUsers?.length) || 0) < 3
      );
    },
  },
  computed: {
    getUsers(): Array<unknown> | null {
      return this.$store.state.userList;
    },
  },
  mounted() {
    this.joinSocket();
    this.socket.on("UPDATE_MATRIX_INFO", (data) => {
      console.log("setting matrix info");
      this.$store.commit(MutationTypes.SET_PAIR_MATRIX, data.pairMatrix);
      this.$store.commit(MutationTypes.SET_USER_LIST, data.users);
      this.$store.commit(MutationTypes.SET_USER_PAIR_SETS, data.pairSets);
    });
    this.socket.on("SET_USER_LIST", (data) => {
      console.log("setting user list ", data);
      this.$store.commit(MutationTypes.SET_USER_LIST, data);
    });
  },
});
