
import { defineComponent } from "@vue/runtime-core";
import axios from "axios";

export default defineComponent({
  name: "UserLogin",
  data() {
    return {
      form: {
        name: "",
        password: "",
      },
      badLoginOccured: false,
    };
  },
  methods: {
    onSubmitLogin(e: { preventDefault: () => void }) {
      e.preventDefault();
      axios
        .post(
          `${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_API_PORT}/login`,
          { name: this.initialName, password: this.form.password },
          { withCredentials: true }
        )
        .then((res) => {
          this.onStatusChange(res.data.success ? "authorized" : "unauthorized");
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.badLoginOccured = true;
          }
        });
    },
    onStatusChange(newStatus: string) {
      this.$emit("pageStatusChange", newStatus);
    },
  },
  props: {
    initialName: String,
  },
});
