
import { defineComponent } from "vue";
import axios from "axios";
import UserLogin from "../components/UserLogin/UserLogin.vue";
import MatrixHolder from "../components/matrixPageParts/MatrixHolder.vue";

export default defineComponent({
  name: "PairMatrixPage",
  data() {
    return {
      pageStatus: "pending",
    };
  },
  components: {
    UserLogin,
    MatrixHolder,
  },
  methods: {
    getAuthenticationStatus() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_API_PORT}/api/authentication/${this.matrixName}`
        )
        .then((response) => {
          this.pageStatus =
            response.data.isUserAuthenticated === true
              ? "authorized"
              : "unauthorized";
        });
    },
    pageStatusChange(newStatus: string) {
      this.pageStatus = newStatus;
    },
    setAuthenticationStatus(newStatus: string) {
      this.pageStatus = newStatus;
    },
  },
  created() {
    this.getAuthenticationStatus();
  },
  props: {
    matrixName: {
      type: String,
      default: "",
    },
  },
});
