
import { defineComponent } from "vue";
import { PairSet } from "../../types/PairSet";
import { User } from "../../types/User";
import MatrixCell from "./MatrixCell/MatrixCell.vue";
import MatrixCellTooltip from "./MatrixCellTooltip/MatrixCellTooltip.vue";
import axios from "axios";
import { MutationTypes } from "../../store/MutationTypes";

export default defineComponent({
  name: "MatrixManager",
  props: ["matrixName", "socket"],
  data() {
    return {
      isInEditMode: false,
      xAxisMouseoverId: null,
      yAxisMouseoverId: null,
    };
  },
  components: { MatrixCell, MatrixCellTooltip },
  methods: {
    getUserList() {
      if (this.$store.state.userList) {
        return this.$store.state.userList;
      }
    },
    getOldestPairName(userId: string): string {
      if (this.getUserList()) {
        const pairList: PairSet[] = this.$store.state.pairSetList;
        const foundPairSet = pairList.filter((set) => {
          return set.userIdList.includes(userId);
        });
        if (foundPairSet.length > 0) {
          const oldestPair = foundPairSet.reduce((oldest, current) => {
            return oldest?.date > current.date ? current : oldest;
          });
          const otherGuyId = oldestPair.userIdList?.filter((id) => {
            return id !== userId;
          });
          const userList: User[] = this.getUserList() || [];
          const otherGuy = userList.filter((user) => {
            return user.id === otherGuyId[0];
          });
          return otherGuy[0].name;
        }
      }
      return "";
    },
    toggleMode() {
      this.isInEditMode = !this.isInEditMode;
    },
    setPairsForTheDay() {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_API_PORT}/api/pairset/batch-create`,
          { pairSetList: this.$store.state.selectedPairs, date: new Date() },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.status === 201) {
            this.socket.emit(
              "TRIGGER_UPDATE_PAIR_SETS",
              this.$store.state.pairMatrix
            );
            this.$store.commit(MutationTypes.SET_SELECTED_PAIR_LIST, []);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    turnEditModeOff() {
      this.isInEditMode = false;
    },
  },
  mounted() {
    this.socket.on("SET_USER_PAIR_SETS", (data: any) => {
      console.log("setting user pairs");
      this.$store.commit(MutationTypes.SET_USER_PAIR_SETS, data);
    });
  },
  computed: {
    getSelectedPairs(): string[][] {
      return this.$store.state.selectedPairs;
    },
  },
});
