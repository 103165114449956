
import { defineComponent } from "vue";
import Datepicker from "vue3-datepicker";

export default defineComponent({
  name: "StyledDatePicker",
  props: ["dateValue"],
  data() {
    return {
      selectedDate: new Date(this.dateValue),
    };
  },
  components: {
    Datepicker,
  },
  methods: {
    emitChange(val: string) {
      this.$emit("matrixCellEdited", val);
    }
  },
  watch: {
    selectedDate(val) {
      this.emitChange(val);
    },
  },
});
