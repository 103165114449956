
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  name: "NewStairOptions",
  data() {
    return {
      isSetToPin: true,
      form: {
        name: "",
        password: "",
      },
    };
  },
  methods: {
    onSubmit(e: { preventDefault: () => void }) {
      e.preventDefault();
      axios
        .post(
          `${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_API_PORT}/api/pairmatrix/`,
          this.form
        )
        .then((res) => {
          if (res.status === 201) {
            axios
              .post(
                `${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_API_PORT}/login`,
                this.form,
                { withCredentials: true }
              )
              .then((res) => {
                if (res.data.success) {
                  this.$router.push(`/pairmatrix/${this.form.name}`);
                }
              });
          }
        });
    },
  },
  computed: {
    isPin() {
      if (
        !isNaN(Number(this.form.password)) &&
        this.form.password.length <= 4
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
});
